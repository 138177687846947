.link-no-style {
    text-decoration: none;
    color: inherit;
}

.cursor-pointer {
    cursor: pointer;
}

.new-line-support {
    white-space: pre-line;
}

.logo{
    background-image: url("../public/logo32.png");
    width: 32px;
    height: 32px;
}